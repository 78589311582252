export const dateTimeFormat = (dateString) => {
  // Parse the input date string into a Date object
  const inputDate = new Date(dateString);

  // Check if the inputDate is a valid Date object
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date'; // Handle invalid input
  }

  // Define an array of month names
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  // Extract the month, day, and year from the Date object
  const month = monthNames[inputDate.getMonth()];
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();

  // Extract the hours and minutes from the Date object
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();

  // Determine whether it's AM or PM
  const amPm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12; // Handle midnight (0) as 12

  // Format the time in the desired format (e.g., "03:30 PM")
  const formattedTime = `${formattedHours}:${String(minutes).padStart(2, '0')} ${amPm}`;

  // Format the date in the desired format (e.g., "Jul 14, 2021 03:30 PM")
  const formattedDate = `${month} ${day}, ${year} - ${formattedTime}`;

  return formattedDate;
};
