import axios from "axios";
import {Toast} from "../../util/Toast";

import { GET_DEACTIVATION_REQUEST } from "./types";

export const getDeactivationRequest = () => (dispatch) => {
  axios
    .get('deactivationRequest')
    .then((res) => {
      if (res.data.status) {
        dispatch({type: GET_DEACTIVATION_REQUEST, payload: res.data.deactivationRequest});
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};
