import React, { useEffect, useState } from "react";

import Select, { createFilter } from 'react-select';
//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_PARTY_CHALLENGE_DIALOG } from "../../store/partyChallenge/types";

import { permissionError } from "../../util/Alert";


import DiamondIcon from "../../assets/images/diamond.png";

import { getAvatarFrame, getSvga } from "../../store/AvatarFrame/action";
import { getBackground } from "../../store/background/action";
import { getCategory } from "../../store/giftCategory/action";
import { getGift } from "../../store/gift/action";


//action
import { editRewardItem } from "../../store/partyChallenge/action";
import { baseURL } from "../../util/Config";

const PartyChallengeLevelDialog = (props) => {
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector((state) => state.partyChallengeLevel);
  const { avatarFrame, svga } = useSelector((state) => state.avatarFrame);
  const { background } = useSelector((state) => state.background);
  const categories = useSelector((state) => state.giftCategory.giftCategory);
  const gift = useSelector((state) => state.gift.gift);
  const hasPermission = useSelector((state) => state.admin.admin.flag);


  const [avatarData, setAvatarData] = useState([]);
  const [svgaData, setSvgaData] = useState([]);
  const [rewardType, setRewardType] = useState('frame');
  const [avatarOptions, setAvatarOptions] = useState([]);
  const [svgaOptions, setSvgaOptions] = useState([]);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [selectedSvga, setSelectedSvga] = useState(null);
  const [backgroundData, setBackgroundData] = useState([]);
  const [backgroundOptions, setBackgroundOptions] = useState([]);
  const [giftOptions, setGiftOptions] = useState([]);
  const [giftCategoryOptions, setGiftCategoryOptions] = useState([]);
  const [selectedGiftCategory, setSelectedGiftCategory] = useState(null);
  const [giftQuantity, setGiftQuantity] = useState(0);
  const [rewardQuantity, setRewardQuantity] = useState(0);
  const [selectedGift, setSelectedGift] = useState(null)
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [validity, setValidity] = useState(1);
  const [validityType, setValidityType] = useState('day');
  const [diamonds, setDiamonds] = useState(1);
  
  // const [submitDialogVisible, setSubmitDialogVisible] = useState(true);

  const [errors, setErrors] = useState({
    uniqueId: "",
    rewardReason: "",
    message: "",
    validity: "",
    validityType: "",
    diamonds: "",
    avatarFrame: "",
    entranceEffect: "",
    background: "",
  });

  const [data, setData] = useState({});

  useEffect(() => {
    props.getAvatarFrame("frame");
    props.getSvga()
    props.getBackground();
    props.getCategory();
  }, []);

  useEffect(() => {
    setAvatarData(avatarFrame);
    const options = avatarFrame.map((item, index) => ({
      value: item._id,
      label: item.name,
      diamond: item.diamond,
      image: `${baseURL}${item.image}`,
      index: index,

    }));
    setAvatarOptions(options);
  }, [avatarFrame]);



  useEffect(() => {

    if (gift && gift.length > 0) {
      console.log("gifts: ", gift)
      const options = gift.map((item, index) => ({
        value: item._id,
        label: item.name,
        diamond: item.coin,
        image: item.type === 2 ? `${baseURL}${item.imagePreview}` : `${baseURL}${item.image}`,
        index: index,

      }));
      setGiftOptions(options);
    }
  }, [gift]);

  useEffect(() => {

    if (categories && categories.length > 0) {
      console.log("cats: ", categories)
      const options = categories.map((item, index) => ({
        value: item._id,
        label: item.name,
        image: `${baseURL}${item.image}`,
        index: index,

      }));
      setGiftCategoryOptions(options);
      props.getGift(categories[0]?._id);
    }
  }, [categories]);

  useEffect(() => {
    if (selectedGiftCategory) {
      const giftsByCategory = props.getGift(selectedGiftCategory)
      console.log("selected gift category ", selectedGiftCategory)
    }
  }, [selectedGiftCategory]);

  useEffect(() => {
    setBackgroundData(background);
    const options = background.map((item, index) => ({
      value: item._id,
      label: item.name,
      diamond: item.diamond,
      image: `${baseURL}${item.image}`,
      index: index,

    }));
    setBackgroundOptions(options);
  }, [background]);

  useEffect(() => {
    setSvgaData(svga);
    const options = svga.map((item, index) => ({
      value: item._id,
      label: item.name,
      diamond: item.diamond,
      image: `${baseURL}${item.imagePreview}`,
      index: index,
    }));
    setSvgaOptions(options);

  }, [svga]);

  const handleRewardSelect = (selected) => {
    if (rewardType === "svga") {
      setSelectedSvga(selected);
    }
    if (rewardType === "frame") {
      setSelectedFrame(selected);
    }
    if (rewardType === "background") {
      setSelectedBackground(selected);
    }
    if (rewardType === "gift") {
      setSelectedGift(selected);
    }

  }


  useEffect(() => {
    if (dialogData) {
      setData(dialogData);
      console.log("dialog data", dialogData);
    }
  }, [dialogData]);

  useEffect(
    () => () => {
      setData(null)
    },
    [open]
  );

  const closePopup = () => {
    dispatch({ type: CLOSE_PARTY_CHALLENGE_DIALOG });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    
    const _errors = {};
    const _data = {};

    if(!rewardQuantity || rewardQuantity === 0) {
      _errors.rewardQuantity = "Reward Quantity is required"
      return setErrors({ ..._errors });
    }


    if (rewardType === 'frame') {
      if (!selectedFrame) {
        _errors.avatarFrame = "Avatar Frame is required.";
        _errors.validity = "Validity is required.";
        _errors.validityType = "Validity Type is required.";
        return setErrors({ ..._errors });
      }
      _data.validity = parseInt(validity);
      _data.validityType = validityType;
      _data.item = selectedFrame.value;
      _data.diamondValue = selectedFrame.diamond;
      console.log("selected rewards", selectedFrame)
    }

    if (rewardType === 'svga') {
      if (!selectedSvga) {
        _errors.entranceEffect = "Entrance Effect is required.";
        _errors.validity = "Validity is required.";
        _errors.validityType = "Validity Type is required.";
        return setErrors({ ..._errors });
      }
      _data.validity = parseInt(validity);
      _data.validityType = validityType;
      _data.item = selectedSvga.value;
      _data.diamondValue = selectedSvga.diamond;
      console.log("selected reward: ", selectedSvga)
    }


    if (rewardType === 'background') {
      if (!selectedBackground) {
        _errors.background = "Background is required.";
        return setErrors({ ..._errors });
      }
      _data.validity = parseInt(validity);
      _data.validityType = validityType;
      _data.item = selectedBackground.value;
      _data.diamondValue = selectedBackground.diamond;
      console.log("selected reward: ", selectedBackground)
    }

    if (rewardType === 'diamond') {
      if (!diamonds) {
        _errors.diamonds = "Diamonds amount is required.";
        return setErrors({ ..._errors });
      }
      _data.diamond = parseInt(diamonds);
      _data.diamondValue = parseInt(diamonds);
    }

    if (rewardType === "gift") {
      if (!giftQuantity || giftQuantity <= 0) {
        _errors.giftQuantity = "Gift quantity is required."
        return setErrors({ ..._errors });
      }
      _data.giftQuantity = parseInt(giftQuantity);
      _data.item = selectedGift.value;
      _data.diamondValue = selectedGift.diamond * giftQuantity;
      console.log("selected reward: ", selectedGift.value)
    }

    _data.rank = data.rank;
    _data.type = rewardType;
    _data.itemQuantity = rewardQuantity;

    const dataForUpdate = {};

    dataForUpdate.level = data.level;
    dataForUpdate.rank = data.rank;
    dataForUpdate.data = _data;

    setSelectedBackground(null);
    setSelectedFrame(null);
    setSelectedGift(null);
    setSelectedGiftCategory(null)
    setSelectedSvga(null)
    setDiamonds(null);

    props.editRewardItem(dataForUpdate);
    closePopup();
  };
  const getItemImage = (data) => {
    
    return data?.item?.imagePreview || data?.item?.image;
  }


  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        disableScrollLock
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4" >
            Edit Reward Item
          </span></DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3 text-light">
            <div className="d-flex flex-column">
              <form>
              <h5 className="text-light mt-2">Reward Level: <span className="text-primary">{data?.level}</span></h5>
                <img
                  src={data?.type === "diamond" ? DiamondIcon : baseURL + getItemImage(data)}
                  style={{ height: 100 }}
                />
                <h5 className="text-light">Diamond Value: <span className="text-primary">{data?.diamondValue}</span></h5>
                <h5 className="text-light">Reward Type: <span className="text-primary">{data?.type}</span></h5>
                <h5 className="text-light mt-2">Reward Rank: <span className="text-primary">{data?.rank}</span></h5>

                <h3 className="text-light mt-4">Change Reward</h3>
                
                <div className='mt-3'>
                      <label className='form-label'>
                        Reward Quantity
                      </label>
                      <input
                        value={rewardQuantity}
                        onChange={e => setRewardQuantity(e.target.value)}
                        type="Number"
                        className="form-control"
                      />
                      {(!rewardQuantity || rewardQuantity <= 0) && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.rewardQuantity}</span>
                          </div>
                        </div>
                      )}
                    </div>

                <label for="reward" className="form-label mt-3">
                  Reward Type
                </label>
                <select
                  className='form-select form-control'
                  value={rewardType}
                  onChange={(event) => setRewardType(event.target.value)}
                >
                  <option value="frame">Avatar Frame</option>
                  <option value="svga">Entrance Effect</option>
                  <option value="background">Background</option>
                  <option value="gift">Live Gift</option>
                  <option value="diamond">Diamond</option>
                </select>

                <div className="mt-3" style={{ display: rewardType === 'diamond' ? 'block' : 'none' }}>
                  <label className="form-label">
                    Diamonds
                  </label>
                  <input
                    value={diamonds}
                    onChange={e => setDiamonds(e.target.value)}
                    type="Number"
                    className="form-control"
                    id="diamonds"
                  />
                  {rewardType === 'diamond' && !diamonds && (
                    <div className="ml-2 mt-1">
                      <div className="pl-1 text__left">
                        <span className="text-red">{errors.diamonds}</span>
                      </div>
                    </div>
                  )}
                </div>


                <div className="mt-3" style={{ display: rewardType === 'gift' ? 'block' : 'none' }}>
                  <label for="giftCategory" className="form-label">
                    Gift Category
                  </label>
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                    value={selectedGiftCategory}
                    onChange={(e) => {
                      setSelectedGiftCategory(e.target.value);
                    }}
                  >
                    {giftCategoryOptions.map((category) => {
                      return (
                        <option value={category.value}>
                          {category.label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="mt-3">
                  <label className="form-label">
                    {rewardType === 'svga' ? 'Entrance Effect' : rewardType === "gift" ? "Live Gift" : rewardType === 'frame' ? 'Avatar Frame' : rewardType === 'background' ? 'Background' : ''}
                  </label>
                  <Select
                    options={rewardType === 'svga' ? svgaOptions : rewardType === 'frame' ? avatarOptions : rewardType === 'background' ? backgroundOptions : rewardType === "gift" ? giftOptions : []}
                    value={rewardType == 'svga' ? selectedSvga : rewardType === 'frame' ? selectedFrame : rewardType === "background" ? selectedBackground : selectedGift}
                    onChange={(selected) => handleRewardSelect(selected)}
                    formatOptionLabel={option => (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img src={option.image} style={{ width: 40, height: 40, marginRight: 10 }} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span>{option.label}</span>
                          <span>ID: {option.value}</span>
                        </div>
                      </div>
                    )}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#e8538f',
                        neutral0: '#1f1f2b',
                        neutral80: '#fff',
                        primary25: '#181821 ',
                        primary50: '#cf779a',
                      },
                    })}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#2d2d3f',
                        borderRadius: 3,
                        display: rewardType === 'diamond' ? 'none' : 'flex',
                      }),
                    }}
                  />
                  {rewardType === 'frame' && !selectedFrame && (
                    <div className="ml-2 mt-1">
                      <div className="pl-1 text__left">
                        <span className="text-red">{errors.avatarFrame}</span>
                      </div>
                    </div>
                  )}
                  {rewardType === 'svga' && !selectedSvga && (
                    <div className="ml-2 mt-1">
                      <div className="pl-1 text__left">
                        <span className="text-red">{errors.entranceEffect}</span>
                      </div>
                    </div>
                  )}
                  {rewardType === 'background' && !selectedBackground && (
                    <div className="ml-2 mt-1">
                      <div className="pl-1 text__left">
                        <span className="text-red">{errors.background}</span>
                      </div>
                    </div>
                  )}
                  {rewardType === 'gift' && !selectedGift && (
                    <div className="ml-2 mt-1">
                      <div className="pl-1 text__left">
                        <span className="text-red">{errors.background}</span>
                      </div>
                    </div>
                  )}
                </div>


                <div className='mt-3' style={{ display: rewardType == 'gift' ? 'block' : 'none' }}>
                      <label className='form-label'>
                        Gift Quantity
                      </label>
                      <input
                        value={giftQuantity}
                        onChange={e => setGiftQuantity(e.target.value)}
                        type="Number"
                        className="form-control"
                        id="quantity"
                      />
                      {rewardType === 'gift' && (!giftQuantity || giftQuantity <= 0) && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.giftQuantity}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='mt-3' style={{ display: rewardType == 'diamond' || rewardType == "gift" ? 'none' : 'block' }}>
                      <label className='form-label'>
                        Validity
                      </label>
                      <input
                        value={validity}
                        onChange={e => setValidity(e.target.value)}
                        type="Number"
                        className="form-control"
                        id="validity"
                      />
                      {(rewardType !== 'diamond') && !validity && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.validity}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mt-3" style={{ display: rewardType == 'diamond' || rewardType == "gift" ? 'none' : 'block' }}>
                      <label for="validityType" className="form-label">
                        Validity Type
                      </label>
                      <select
                        value={validityType}
                        onChange={e => setValidityType(e.target.value)}
                        className="form-select form-control"
                        id="validityType"
                      >
                        <option value="day">
                          Day
                        </option>
                        <option value="month">
                          Month
                        </option>
                        <option value="year">
                          Year
                        </option>
                      </select>
                    </div>

                <div className="d-flex flex-row gap-2 mt-3 justify-content-end">
                  <button
                    onClick={closePopup}
                    type="button"
                    className="btn btn-outline-info">
                    Cancel
                  </button>
                  <button onClick={handleSubmit} type="button" className="btn btn-danger">Save</button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, {
  editRewardItem,
  getAvatarFrame,
  getSvga,
  getBackground,
  getCategory,
  getGift,
})(PartyChallengeLevelDialog);
