import React, { useEffect } from "react";

//redux
import { connect, useDispatch } from "react-redux";

//action
import { login } from "../store/admin/action";


const LoginPage = (props) => {
  // const [email, setEmail] = useState();
  // const [password, setPassword] = useState();
  // const [error, setError] = useState({
  //   email: "",
  //   password: "",
  // });
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!email || !password) {
  //     const error = {};

  //     if (!email) error.email = "Email is Required!";
  //     if (!password) error.password = "Password is Required!";

  //     return setError({ ...error });
  //   }
  //   const data = {
  //     email,
  //     password,
  //   };
  //   props.login(data);
  // };

  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code) {
      dispatch(login(code));
    } 
  }, [dispatch]);

  const handleLarkLogin = () => {
    const REDIRECT_URI = encodeURIComponent('https://appadmin.vibelynk.com');
    const LARK_APP_ID = 'cli_a7c790b46d79d02f';
    const state = Math.random().toString(36).substring(7);
    const authUrl = `https://open.larksuite.com/open-apis/authen/v1/authorize?app_id=${LARK_APP_ID}&redirect_uri=${REDIRECT_URI}&state=${state}`;
    console.log(authUrl)
    window.location.href = authUrl; // Redirect user to Lark authorization URL
  };
  return (
    <>
      <div className="login-page back__style">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 col-lg-4">
              <div className="card login-box-container">
                <div className="card-body">
                  <div>
                  <img style={{height: 70, width: 70, borderRadius: 10, display: "flex", margin: "auto"}} src="/vibelynk_logo.png" />
                  </div>
                  <div className="authent-logo mb-4 mt-2">
                    <span className="text-danger h3">VibeLynk</span>
                  </div>
                  <div className="authent-text">
                    {/* <p>Welcome to FunMate</p> */}
                    {/* <p>
                      Enter your email address and password to access admin
                      panel.
                    </p> */}
                  </div>

                  <div >
                    {/* <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          required
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                email: "Email is Required !",
                              });
                            } else {
                              return setError({
                                ...error,
                                email: "",
                              });
                            }
                          }}
                        />
                        <label for="floatingInput">Email address</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.email && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red font-size-lg">
                              {error.email}
                            </span>
                          </div>
                        )}
                      </div>
                    </div> */}
                    {/* <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...error,
                                password: "Password is Required !",
                              });
                            } else {
                              return setError({
                                ...error,
                                password: "",
                              });
                            }
                          }}
                        />
                        <label for="floatingPassword">Password</label>
                      </div>
                      <div className="mt-2 ml-2 mb-3">
                        {error.password && (
                          <div className="pl-1 text-left pb-1">
                            <span className="text-red">{error.password}</span>
                          </div>
                        )}
                      </div>
                    </div> */}
                    {/* <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-danger m-b-xs"
                        onClick={handleSubmit}
                      >
                        Sign In
                      </button>
                    </div> */}
                    <div className="d-grid">
                      <button
                        className="btn btn-danger m-b-xs"
                        onClick={handleLarkLogin}
                      >
                        Sign In with Lark
                      </button>
                    </div>
                  </div>
                  {/* <div className="authent-reg">
                    <p>
                      <Link to="/forgot" className="text-info">
                        Forgot password?
                      </Link>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { login })(LoginPage);
