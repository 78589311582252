import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  CLOSE_DIAMOND_SHOP_DIALOG,
  CREATE_SHOP_DIAMOND,
  DELETE_SHOP_DIAMOND,
  EDIT_SHOP_DIAMOND,
  GET_SHOP_DIAMOND,
  GET_SELLER_SHOP_DIAMOND,
} from "./types";

export const getShopDiamond = () => (dispatch) => {
  axios
    .get(`shopDiamond`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_SHOP_DIAMOND, payload: res.data.shopDiamonds });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const getSellerShopDiamond = () => (dispatch) => {
  axios
    .get(`shopDiamond?forSeller=true`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_SELLER_SHOP_DIAMOND, payload: res.data.shopDiamonds });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};


export const createShopDiamond = (data) => (dispatch) => {
  axios
    .post(`shopDiamond`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Diamond created successfully!");
        dispatch({ type: CLOSE_DIAMOND_SHOP_DIALOG });
        dispatch({ type: CREATE_SHOP_DIAMOND, payload: res.data.shopDiamond });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editShopDiamond = (mongoId, data) => (dispatch) => {
  console.log(data)
  axios
    .patch('shopDiamond', data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Diamond updated successfully!");
        dispatch({ type: CLOSE_DIAMOND_SHOP_DIALOG });
        dispatch({
          type: EDIT_SHOP_DIAMOND,
          payload: { data: res.data.shopDiamond, id: mongoId },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const deleteShopDiamond = (id) => (dispatch) => {
  axios
    .delete(`shopDiamond/?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_SHOP_DIAMOND, payload: id });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
