import React, { useEffect, useRef } from 'react';
import SVGA from 'svgaplayerweb';

function SVGAAnimation({ animationUrl, styles }) {
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.clear(); // Clear the previous animation
    }

    playerRef.current = new SVGA.Player(containerRef.current);
    const parser = new SVGA.Parser(containerRef.current);

    parser.load(animationUrl, function(videoItem) {
      playerRef.current.setVideoItem(videoItem);
      playerRef.current.startAnimation();
    });

    return () => {
      if (playerRef.current) {
        playerRef.current.clear(); // Clear the player instance when the component unmounts
      }
    };

  }, [animationUrl]);

  return <div style={{ width: 100, height: 100, ...styles}} ref={containerRef} />;
}

export default SVGAAnimation;
