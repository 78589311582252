import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { editBackground, createNewBackground } from "../../store/background/action";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Switch,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { baseURL } from "../../util/Config";
import { CLOSE_BACKGROUND_DIALOG } from "../../store/background/types";

const BackgroundDialog = (props) => {
  const { Dialogue, DialogueData } = useSelector((state) => state.background);
  const [mongoId, setMongoId] = useState("");
  const [validity, setValidity] = useState("");
  const [validityType, setValidityType] = useState("");
  const [diamond, setDiamond] = useState("");
  const [name, setName] = useState("");
  const [errors, setError] = useState("");
  const [imageData, setImageData] = useState("");
  const [imagePath, setImagePath] = useState("");

  const [showInStore, setShowInStore] = useState(true);

  useEffect(() => {
    setError("");
    setName("");
    setDiamond("");
    setImageData(null);
  }, [Dialogue]);


  useEffect(() => {
    // debugger
    if (DialogueData) {
      setValidity(DialogueData?.validity);
      setValidityType(DialogueData?.validityType);
      setMongoId(DialogueData?._id);
      setDiamond(DialogueData?.diamond);
      setName(DialogueData?.name);
      setImagePath(baseURL + DialogueData?.image);
      setShowInStore(DialogueData?.showInStore)
    }
  }, [DialogueData]);

  useEffect(
    () => () => {
      setValidity("")
      setValidityType("")
      setError("");
      setName("");
      setDiamond("");
      setImageData(null);
      setImagePath(null);
    },
    [open]
  );

  const dispatch = useDispatch();

  const closePopup = () => {
    dispatch({ type: CLOSE_BACKGROUND_DIALOG });
  };

  const handleSubmit = () => {
    if (
      !name || !diamond || diamond < 0 || !mongoId
        ? !imageData
        : !imagePath || !validity
    ) {
      const errors = {};
      if (!name) errors.name = "Name is Required";
      if (validity < 0) errors.validity = "invalid value of validity";
      if (!validity) errors.validity = "Validity is required!";
      if (!diamond) errors.diamond = "Diamond is required";
      if (diamond < 0) errors.diamond = "Invalid Diamond ";
      // if (images.length === 0) errors.images = "Please select an Image!";

      return setError({ ...errors });
    } else {
      const formData = new FormData();
      formData.append("diamond", diamond);
      formData.append("validity", validity);
      formData.append("validityType", validityType ? validityType : "day");
      formData.append("showInStore", showInStore);
      // if (DialogueData) {
      //   formData.append("background", imageData);
      // } else {
      //   formData.append("background", addImageData);
      // }
      formData.append("background", imageData);
      formData.append("name", name);
      if (DialogueData) {
        props.editBackground(mongoId, formData);
      } else {
        props.createNewBackground(formData);
      }
    }
    closePopup();
  };


  const HandleInputImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      <Dialog
        open={Dialogue}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">Background</span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>

        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="form-group mt-4">
                    <label className="text-gray mb-2">Name</label>

                    <input
                      type="text"
                      className="form-control"
                      required=""
                      placeholder="Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            name: "Name is Required!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            name: "",
                          });
                        }
                      }}
                    />

                    {errors.name && (
                      <div className="ml-2 mt-1">
                        {errors.name && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.name}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>



                  <div className="form-group mt-4">
                    <label className="text-gray mb-2">Show in store</label>

                    <Switch
                      checked={showInStore}
                      onChange={(event) => setShowInStore(event.target.checked)}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />

                  </div>



                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label className="text-gray mb-2">Validity</label>
                      <input
                        type="number"
                        className="form-control"
                        required=""
                        placeholder="1"
                        min="0"
                        value={validity}
                        onChange={(e) => {
                          setValidity(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              validity: "Validity is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              validity: "",
                            });
                          }
                        }}
                      />
                      {errors.validity && (
                        <div className="ml-2 mt-1">
                          {errors.validity && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.validity}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="form-group">
                      <label className="text-gray mb-2">Validity Type</label>
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                        value={validityType}
                        onChange={(e) => {
                          setValidityType(e.target.value);
                        }}
                      >
                        <option value="day" >
                          Day
                        </option>
                        <option value="Month">Month</option>
                        <option value="year">Year</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label className="mb-2 text-gray">Diamond</label>
                  <input
                    type="number"
                    className="form-control"
                    required=""
                    min="0"
                    placeholder="20"
                    value={diamond}
                    onChange={(e) => {
                      setDiamond(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          diamond: "diamond is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          diamond: "",
                        });
                      }
                    }}
                  />
                  {errors.diamond && (
                    <div className="ml-2 mt-1">
                      {errors.diamond && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.diamond}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="form-group mt-4">
                  <label className="mb-2 text-gray">Select Image</label>
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    accept="image/*"
                    required=""
                    onChange={HandleInputImage}
                  />
                  {/* {errors.image && (
                    <div className="ml-2 mt-1">
                      {errors.image && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.image}</span>
                        </div>
                      )}
                    </div>
                  )} */}
                  {imagePath && (
                    <>
                      <img
                        height="70px"
                        width="70px"
                        alt="app"
                        src={imagePath}
                        style={{
                          boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                          // border: "2px solid #fff",
                          borderRadius: 10,
                          marginTop: 10,
                          float: "left",
                          objectFit: "cover",
                        }}
                      />
                    </>
                  )}
                </div>

                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { editBackground, createNewBackground })(BackgroundDialog);
