import React, { useEffect, useState } from "react";

const SendGift = () => {
    return(
        <>
        <h1>Send Gift</h1>
        </>
    )
}

export default SendGift;