import React, { useEffect, useState } from "react";


//react-redux
import { useDispatch, useSelector, connect } from "react-redux";

//action
import { getPartyChallengeLevel } from "../store/partyChallenge/action";


//routing
import { Link } from "react-router-dom";

import { OPEN_PARTY_CHALLENGE_DIALOG } from "../store/partyChallenge/types";
import PartyChallengeLevelItemDialog from "../component/dialog/PartyChallengeLevelItemDialog";

//server path
import { baseURL } from "../util/Config";

import PartyChallengeLevelCard from "../component/party_challenge/PartyChallengeLevelCard";

const PartyChallenge = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [dataForEdit, setDataForEdit] =  useState({});

  useEffect(() => {
    props.getPartyChallengeLevel();
  }, [])

  const editRewardItem = (data) => {
    console.log("edit data", data);
    dispatch({ type: OPEN_PARTY_CHALLENGE_DIALOG, payload: data });
    setDataForEdit(data);
  }

  const partyChallengeLevel = useSelector((state) => state.partyChallengeLevel.partyChallengeLevel);
  useEffect(() => {
    setData(partyChallengeLevel)
    console.log("Levels:", partyChallengeLevel)
  }, [partyChallengeLevel]);

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Party Challenge</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Party Challenge
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {data.map((level) => (
        <div key={level?._id}>
          <PartyChallengeLevelCard
            data={level}
            editData={editRewardItem}
          />
        </div>
      ))}
      <PartyChallengeLevelItemDialog />
    </>
  );
};

export default connect(null, { getPartyChallengeLevel })(PartyChallenge);
