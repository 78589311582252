import axios from "axios";
import { Toast } from "../../util/Toast";

import {
  GET_REPORTED_MOMENTS,
  DELETE_POST,
  DISMISS_REPORT,
} from "./types";

export const getReportedMoments = () => (dispatch) => {
  axios
    .get("/postReport/moments")
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_REPORTED_MOMENTS, payload: res.data.report });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};


export const deletePost = (mongoId, postId, postType) => (dispatch) => {
  axios
  .post("/postReport/action?action=delete", {postId, postType})
  .then((res) => {
    if(res.data.status) {
      dispatch({ type: DELETE_POST, payload: { postId: mongoId } })
    } else {
      Toast("error", res.data.message);
    }
  })
  .catch ((error) => Toast("error", error.message ));
}

export const dismissReport = (mongoId, postId, postType) => (dispatch) => {
  axios
  .post("/postReport/dismiss", {postId, postType})
  .then((res) => {
    if (res.data.status) {
      dispatch({ type: DISMISS_REPORT, payload: { postId: mongoId }});
    } else {
      Toast("error", res.data.message);
    }
  })
  .catch((error) => Toast("error", error.message ));
}