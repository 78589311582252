import React, { useEffect, useState } from "react";

//routing
import { Link } from "react-router-dom";

import ChestBoxL1 from "../../assets/images/treasure_box/Treasure Box level 1.png";
import ChestBoxL2 from "../../assets/images/treasure_box/Treasure Box level 2.png";
import ChestBoxL3 from "../../assets/images/treasure_box/Treasure Box level 3.png";
import ChestBoxL4 from "../../assets/images/treasure_box/Treasure Box level 4.png";
import ChestBoxL5 from "../../assets/images/treasure_box/Treasure Box level 5.png";
import ChestBoxL6 from "../../assets/images/treasure_box/Treasure Box level 6.png";
import ChestBoxL7 from "../../assets/images/treasure_box/Treasure Box level 7.png";
import ChestBoxL8 from "../../assets/images/treasure_box/Treasure Box level 8.png";
import { baseURL } from "../../util/Config";

import DiamondIcon from "../../assets/images/diamond.png";

const chestBoxStyle = {
  width: "100px",
  height: "auto",
};

const PartyChallengeLevelCard = ({ data, editData }) => {

  const reward1 = data?.rewards.find(reward => reward.rank === 1);
  const reward2 = data?.rewards.find(reward => reward.rank === 2);
  const reward3 = data?.rewards.find(reward => reward.rank === 3);
  const reward4 = data?.rewards.find(reward => reward.rank === 4);
  const reward5 = data?.rewards.find(reward => reward.rank === 5);
  const reward6 = data?.rewards.find(reward => reward.rank === 6);
  const reward7 = data?.rewards.find(reward => reward.rank === 7);

  const getItemImage = (data) => {
    
    return data?.item?.imagePreview || data?.item?.image;
  }

  const getItemName = (data) => {

    return data?.item?.name || "";
  }

  const editRewardItem = (_data) => {
    // console.log("edit data", data);
    const __data = { ..._data, ...data }
    console.log("___data", __data)
    editData(__data)
  }

  const getChestBoxImage = () => {
    if (data?.level === 1) {
      return ChestBoxL1;
    }
    if (data?.level === 2) {
      return ChestBoxL2;
    }
    if (data?.level === 3) {
      return ChestBoxL3;
    }
    if (data?.level === 4) {
      return ChestBoxL4;
    }
    if (data?.level === 5) {
      return ChestBoxL5;
    }
    if (data?.level === 6) {
      return ChestBoxL6;
    }
    if (data?.level === 7) {
      return ChestBoxL7;
    }
    if (data?.level === 8) {
      return ChestBoxL8;
    }
  }
  return (
    <>
      <div className="col">
        <div className="card p-4">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-center">
              <img style={chestBoxStyle} src={getChestBoxImage()} />
              <div className="d-flex flex-column">
                <h4 className="text-light">Level {data?.level}</h4>
                <h5 className="text-light">Goal: <spam className="text-primary">{data?.goal}</spam></h5>
              </div>
            </div>
          </div>

          <h4 className="text-light mt-4">Rewards</h4>

          <div className="mt-2 d-flex flex-row justify-content-around">
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex flex-row align-items-end">
                <img
                  style={{ width: 200 }}
                  src={reward1?.type === "diamond" ? DiamondIcon : baseURL + getItemImage(reward1)}
                />
                <button
                  className="fas fa-edit text-white p-2 bg-primary rounded-circle"
                  style={{ fontSize: 15 }}
                  onClick={() => editRewardItem(reward1)}></button>
              </div>
              <div className="d-flex flex-column align-items-center" style={{ marginRight: 35}}>
              <span>Main Reward</span>
              <span>{getItemName(reward1)}</span>
              <span className="text-primary">{reward1?.diamondValue}</span>
              </div>
            </div>
            <div className="row row-cols-3">

              {[reward2, reward3, reward4, reward5, reward6, reward7].map((reward, index) => (
                <div className="col" key={index + 2}>
                  <div className="d-flex flex-column align-items-center mx-2 my-4">
                    <div className="d-flex flex-row align-items-end">
                      <img style={{ height: 100 }} src={reward?.type === "diamond" ? DiamondIcon : baseURL + getItemImage(reward)} />
                      <button
                        onClick={() => editRewardItem(reward)}
                        className="fas fa-edit text-white p-2 bg-primary rounded-circle"
                        style={{ fontSize: 15, marginRight: -35 }}
                      />
                    </div>
                    <span>Reward {reward?.rank}</span>
                    <span>
                      {getItemName(reward)}
                      {reward?.type === "gift" && (
                      ` (x${reward.giftQuantity})`
                    )}  
                    </span>

                    <span className="text-primary">{reward?.diamondValue}</span>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartyChallengeLevelCard;
