import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  EDIT_ITEM,
  GET_PARTY_CHALLENGE_LEVEL
} from "./types";

export const getPartyChallengeLevel = () => (dispatch) => {
  axios
    .get(`partyChallengeLevel`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_PARTY_CHALLENGE_LEVEL, payload: res.data.partyChallengeLevel });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const editRewardItem = (data) => (dispatch) => {
  axios
    .patch(`partyChallengeLevel`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_ITEM,
          payload: res.data.partyChallengeLevel
        });

      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
}