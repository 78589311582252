export const GET_POST = "GET_POST";
export const GET_COMMENT = "GET_COMMENT";
export const GET_LIKE = "GET_LIKE";
export const DELETE_POST = "DELETE_POST";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const INSERT_POST = "INSERT_POST";
export const GET_USER_PROFILE = "GET_USER_PROFILE"

export const RESET_USER_IMAGE = "RESET_USER_IMAGE";

export const UPDATE_USER_INFO ="UPDATE_USER_INFO"  
