import {
  GET_REWARD_HISTORY,
  GET_USER_FRAMES,
  GET_USER_SVGAS,
  SEND_REWARD
} from "./types";

const initialState = {
  history: [],
  userFrames: [],
  userSvgas: [],
};

const rewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REWARD_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case SEND_REWARD:
      const data = [...state.history];
      data.unshift(action.payload);
      return {
        ...state,
        history: data,
      };
    case GET_USER_FRAMES:
      return {
        ...state,
        userFrames: action.payload,
      };
    case GET_USER_SVGAS:
      return {
        ...state,
        userSvgas: action.payload,
      };

    default:
      return state;
  }
};

export default rewardReducer;
