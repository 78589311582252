import {
  GET_PARTY_CHALLENGE_LEVEL,
  EDIT_ITEM,
  OPEN_PARTY_CHALLENGE_DIALOG,
  CLOSE_PARTY_CHALLENGE_DIALOG
} from "./types";

const initialState = {
  partyChallengeLevel: [],
  dialog: false,
  dialogData: null,
};

const partyChallengeLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTY_CHALLENGE_LEVEL:
      return {
        ...state,
        partyChallengeLevel: action.payload,
      };

    case EDIT_ITEM:
      return {
        ...state,
        partyChallengeLevel: state.partyChallengeLevel.map((_level) => {
          console.log("level from reducer:", action.payload)
          if (_level.level === action.payload.level) return action.payload;
          else return _level;
        }),
      }

    case OPEN_PARTY_CHALLENGE_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_PARTY_CHALLENGE_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};

export default partyChallengeLevelReducer;
