import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAvatarFrame, getSvga } from "../store/AvatarFrame/action";
import { getBackground } from "../store/background/action";
import { getCategory } from "../store/giftCategory/action";
import { getGift } from "../store/gift/action";
import { sendReward } from "../store/inventory/action";
import { Link } from 'react-router-dom'
import Select, { createFilter } from 'react-select';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SVGA from "svgaplayerweb";
import { baseURL } from "../util/Config";
//sweet alert
import { alert, warning, permissionError } from "../util/Alert";
import SVGAAnimation from "../component/SvgaAnimation";

const SendReward = (props) => {

  const { avatarFrame, svga } = useSelector((state) => state.avatarFrame);
  const { background } = useSelector((state) => state.background);
  const categories = useSelector((state) => state.giftCategory.giftCategory);
  const gift = useSelector((state) => state.gift.gift);
  const [avatarData, setAvatarData] = useState([]);
  const [svgaData, setSvgaData] = useState([]);
  const [rewardType, setRewardType] = useState('frame');
  const [avatarOptions, setAvatarOptions] = useState([]);
  const [svgaOptions, setSvgaOptions] = useState([]);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [selectedSvga, setSelectedSvga] = useState(null);
  const [backgroundData, setBackgroundData] = useState([]);
  const [backgroundOptions, setBackgroundOptions] = useState([]);
  const [giftOptions, setGiftOptions] = useState([]);
  const [giftCategoryOptions, setGiftCategoryOptions] = useState([]);
  const [selectedGiftCategory, setSelectedGiftCategory] = useState(null);
  const [giftQuantity, setGiftQuantity] = useState(0);
  const [selectedGift, setSelectedGift] = useState(null)
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [svgaPlayer, setSvgaPlayer] = useState(null);
  const [uniqueId, setUniqueId] = useState('');
  const [rewardReason, setRewardReason] = useState('');
  const [message, setMessage] = useState('');
  const [validity, setValidity] = useState(1);
  const [validityType, setValidityType] = useState('day');
  const [diamonds, setDiamonds] = useState(1);
  const [submitDialogVisible, setSubmitDialogVisible] = useState(true);

  const [errors, setErrors] = useState({
    uniqueId: "",
    rewardReason: "",
    message: "",
    validity: "",
    validityType: "",
    diamonds: "",
    avatarFrame: "",
    entranceEffect: "",
    background: "",
  });

  useEffect(() => {
    props.getAvatarFrame("frame");
    props.getSvga()
    props.getBackground();
    props.getCategory();
  }, []);

  useEffect(() => {
    setAvatarData(avatarFrame);
    const options = avatarFrame.map((item, index) => ({
      value: item._id,
      label: item.name,
      image: `${baseURL}${item.image}`,
      index: index,

    }));
    setAvatarOptions(options);
  }, [avatarFrame]);

  useEffect(() => {

    if (gift && gift.length > 0) {
      console.log("gifts: ", gift)
      const options = gift.map((item, index) => ({
        value: item._id,
        label: item.name,
        image: item.type === 2 ? `${baseURL}${item.imagePreview}` : `${baseURL}${item.image}`,
        index: index,

      }));
      setGiftOptions(options);
    }
  }, [gift]);

  useEffect(() => {

    if (categories && categories.length > 0) {
      console.log("cats: ", categories)
      const options = categories.map((item, index) => ({
        value: item._id,
        label: item.name,
        image: `${baseURL}${item.image}`,
        index: index,

      }));
      setGiftCategoryOptions(options);
      props.getGift(categories[0]?._id);
    }
  }, [categories]);

  useEffect(() => {
    if (selectedGiftCategory) {
      const giftsByCategory = props.getGift(selectedGiftCategory)
      console.log("selected gift category ", selectedGiftCategory)
    }
  }, [selectedGiftCategory]);

  useEffect(() => {
    setBackgroundData(background);
    const options = background.map((item, index) => ({
      value: item._id,
      label: item.name,
      image: `${baseURL}${item.image}`,
      index: index,

    }));
    setBackgroundOptions(options);
  }, [background]);

  useEffect(() => {
    setSvgaData(svga);
    const options = svga.map((item, index) => ({
      value: item._id,
      label: item.name,
      image: `${baseURL}${item.imagePreview}`,
      index: index,
    }));
    setSvgaOptions(options);

  }, [svga]);

  const handleSubmit = () => {

    const errors = {};
    const data = {};

    if (!uniqueId || !rewardReason || !message) {
      errors.uniqueId = "User ID is required.";
      errors.rewardReason = "Reward reason is required.";
      errors.message = "Message is required.";
      return setErrors({ ...errors });
    }
    data.uniqueId = uniqueId;
    data.rewardReason = rewardReason;
    data.message = message;
    data.type = rewardType;

    if (rewardType === 'frame') {
      if (!selectedFrame) {
        errors.avatarFrame = "Avatar Frame is required.";
        errors.validity = "Validity is required.";
        errors.validityType = "Validity Type is required.";
        return setErrors({ ...errors });
      }
      data.validity = parseInt(validity);
      data.validityType = validityType;
      data.frame = selectedFrame.value;
    }

    if (rewardType === 'svga') {
      if (!selectedSvga) {
        errors.entranceEffect = "Entrance Effect is required.";
        errors.validity = "Validity is required.";
        errors.validityType = "Validity Type is required.";
        return setErrors({ ...errors });
      }
      data.validity = parseInt(validity);
      data.validityType = validityType;
      data.svga = selectedSvga.value;
    }


    if (rewardType === 'background') {
      if (!selectedBackground) {
        errors.background = "Background is required.";
        errors.validity = "Validity is required.";
        errors.validityType = "Validity Type is required.";
        return setErrors({ ...errors });
      }
      data.validity = parseInt(validity);
      data.validityType = validityType;
      data.background = selectedBackground.value;
    }

    if (rewardType === 'diamond') {
      if (!diamonds) {
        errors.diamonds = "Diamonds amount is required.";
        return setErrors({ ...errors });
      }
      data.diamond = parseInt(diamonds);
    }

    if (rewardType === "gift") {
      if (!giftQuantity || giftQuantity <= 0) {
        errors.giftQuantity = "Gift quantity is required."
        return setErrors({ ...errors });
      }
      data.quantity = parseInt(giftQuantity);
      data.gift = selectedGift.value;
    }
    props.sendReward(data);
  }

  const handleRewardSelect = (selected) => {
    if (rewardType === "svga") {
      setSelectedSvga(selected);
    }
    if (rewardType === "frame") {
      setSelectedFrame(selected);
    }
    if (rewardType === "background") {
      setSelectedBackground(selected);
    }
    if (rewardType === "gift") {
      setSelectedGift(selected);
    }

  }
  const handleClearFields = () => {
    setUniqueId('');
    setRewardReason('');
    setMessage('');
    setRewardType('frame');
    setSelectedFrame(null);
    setSelectedSvga(null);
    setValidity(1);
    setValidityType('Days');
    setDiamonds(0);
  }
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-light" style={{ color: "#e4eeff" }}>
              Send Reward
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Send Reward
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <form>
                  <div className="mb-3 row">

                    <div className="col-md-4">
                      <label for="uniqueId" className="form-label">
                        Voice Vibe ID
                      </label>
                      <input
                        value={uniqueId}
                        onChange={e => setUniqueId(e.target.value)}
                        className="form-control"
                        id="uniqueId"
                      />
                      {!uniqueId && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.uniqueId}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="rewardReason" className="form-label">
                        Reward Reason
                      </label>
                      <input
                        value={rewardReason}
                        onChange={e => setRewardReason(e.target.value)}
                        className="form-control"
                        id="rewardReason"
                      />
                      {!rewardReason && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.rewardReason}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="rewardMessage" className="form-label">
                        Message
                      </label>
                      <input
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        className="form-control"
                        id="rewardMessage"
                      />
                      {!message && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.message}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mt-5'>
                    <div className="mb-3 col-md-4">
                      <label for="reward" className="form-label">
                        Reward Type
                      </label>
                      <select
                        className='form-select form-control'
                        value={rewardType}
                        onChange={(event) => setRewardType(event.target.value)}
                      >
                        <option value="frame">Avatar Frame</option>
                        <option value="svga">Entrance Effect</option>
                        <option value="background">Background</option>
                        <option value="gift">Live Gift</option>
                        <option value="diamond">Diamond</option>
                      </select>
                    </div>

                    <div className="mb-3 col-md-4" style={{ display: rewardType === 'gift' ? 'block' : 'none' }}>
                      <label for="giftCategory" className="form-label">
                        Gift Category
                      </label>
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                        value={selectedGiftCategory}
                        onChange={(e) => {
                          setSelectedGiftCategory(e.target.value);
                        }}
                      >
                        {giftCategoryOptions.map((category) => {
                          return (
                            <option value={category.value}>
                              {category.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-md-4" style={{ display: rewardType === 'diamond' ? 'block' : 'none' }}>
                      <label className="form-label">
                        Diamonds
                      </label>
                      <input
                        value={diamonds}
                        onChange={e => setDiamonds(e.target.value)}
                        type="Number"
                        className="form-control"
                        id="diamonds"
                      />
                      {rewardType === 'diamond' && !diamonds && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.diamonds}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-md-12 mt-4">
                      {rewardType === 'frame' ? (
                        <img src={selectedFrame.image} style={{ width: 100, height: 100, marginRight: 10}} />
                      ): rewardType === 'svga' && selectedSvga ? (
                        <div attr="svga" style={{ width: 100, height: 100, marginRight: 10}} />
                      ) : null}
                  </div> */}
                  <div className='row mt-2'>
                    <div className='col-md-4'>
                      <label className="form-label">
                        {rewardType === 'svga' ? 'Entrance Effect' : rewardType === 'frame' ? 'Avatar Frame' : rewardType === 'background' ? 'Background' : ''}
                      </label>
                      <Select
                        options={rewardType === 'svga' ? svgaOptions : rewardType === 'frame' ? avatarOptions : rewardType === 'background' ? backgroundOptions : rewardType === "gift" ? giftOptions : []}
                        value={rewardType == 'svga' ? selectedSvga : rewardType === 'frame' ? selectedFrame : rewardType === "background" ? selectedBackground : selectedGift}
                        onChange={(selected) => handleRewardSelect(selected)}
                        formatOptionLabel={option => (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <img src={option.image} style={{ width: 40, height: 40, marginRight: 10 }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>{option.label}</span>
                              <span>ID: {option.value}</span>
                            </div>
                          </div>
                        )}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary: '#e8538f',
                            neutral0: '#1f1f2b',
                            neutral80: '#fff',
                            primary25: '#181821 ',
                            primary50: '#cf779a',
                          },
                        })}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: '#2d2d3f',
                            borderRadius: 3,
                            display: rewardType === 'diamond' ? 'none' : 'flex',
                          }),
                        }}
                      />
                      {rewardType === 'frame' && !selectedFrame && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.avatarFrame}</span>
                          </div>
                        </div>
                      )}
                      {rewardType === 'svga' && !selectedSvga && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.entranceEffect}</span>
                          </div>
                        </div>
                      )}
                      {rewardType === 'background' && !selectedBackground && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.background}</span>
                          </div>
                        </div>
                      )}
                      {rewardType === 'gift' && !selectedGift && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.background}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col-md-4' style={{ display: rewardType == 'gift' ? 'block' : 'none' }}>
                      <label className='form-label'>
                        Gift Quantity
                      </label>
                      <input
                        value={giftQuantity}
                        onChange={e => setGiftQuantity(e.target.value)}
                        type="Number"
                        className="form-control"
                        id="quantity"
                      />
                      {rewardType === 'gift' && (!giftQuantity || giftQuantity <= 0) && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.giftQuantity}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col-md-4' style={{ display: rewardType == 'diamond' || rewardType == "gift" ? 'none' : 'block' }}>
                      <label className='form-label'>
                        Validity
                      </label>
                      <input
                        value={validity}
                        onChange={e => setValidity(e.target.value)}
                        type="Number"
                        className="form-control"
                        id="validity"
                      />
                      {(rewardType !== 'diamond') && !validity && (
                        <div className="ml-2 mt-1">
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.validity}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4" style={{ display: rewardType == 'diamond' || rewardType == "gift" ? 'none' : 'block' }}>
                      <label for="validityType" className="form-label">
                        Validity Type
                      </label>
                      <select
                        value={validityType}
                        onChange={e => setValidityType(e.target.value)}
                        className="form-select form-control"
                        id="validityType"
                      >
                        <option value="day">
                          Day
                        </option>
                        <option value="month">
                          Month
                        </option>
                        <option value="year">
                          Year
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-3 mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleClearFields}
                    >
                      Clear fields
                    </button>

                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(null, {
  getAvatarFrame,
  getSvga,
  sendReward,
  getBackground,
  getCategory,
  getGift,
})(SendReward);
