import axios from "axios";
import {Toast} from "../../util/Toast";

import { SEND_REWARD, GET_REWARD_HISTORY, GET_USER_FRAMES, GET_USER_SVGAS } from "./types";

export const getRewardHistory = () => (dispatch) => {
  axios
    .get('inventory?reward=true')
    .then((res) => {
      if (res.data.status) {
        dispatch({type: GET_REWARD_HISTORY, payload: res.data.inventoryHistory});
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};

export const sendReward = (data) => (dispatch) => {
  axios
  .post("inventory?reward=true", {data: data})
  .then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: SEND_REWARD,
        payload: res.data.inventory,
      });
      Toast("success", "Reward sent successfully!");
    } else {
      Toast("error", res.data.message);
    }
  })
  .catch((error) => Toast("error", error.message));
}

export const getUserInventory = (userId, type) => (dispatch) => {
  axios
    .get(`inventory/${userId}/${type}`)
    .then((res) => {
      if (res.data.status === true) {
        if (type === "frame") {
          dispatch({
            type: GET_USER_FRAMES,
            payload: res.data.inventory,
          });
        } else {
          dispatch({
            type: GET_USER_SVGAS,
            payload: res.data.inventory,
          });
        }

        console.log(`type: ${type}`,  res.data.inventory)
      } else {
        Toast("error", res.data.message);
      }
    }).catch((error) => Toast("error", error.message));
}
