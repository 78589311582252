import { GET_REPORTED_MOMENTS, DISMISS_REPORT, DELETE_POST } from "./types";

const initialState = {
  reportedMoments: [],
};

const ReportedMomentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTED_MOMENTS:
      return {
        ...state,
        reportedMoments: action.payload,
      };
    case DISMISS_REPORT:
      return {
        ...state,
        reportedMoments: state.reportedMoments.filter((data) => data._id._id !== action.payload.postId),
      }
    case DELETE_POST:
      return {
        ...state,
        reportedMoments: state.reportedMoments.filter((data) => data._id._id !== action.payload.postId),
      }
    default:
      return state;
  }
};

export default ReportedMomentsReducer;
