export const GET_VIP_PLAN = "GET_VIP_PLAN";
export const CREATE_NEW_VIP_PLAN = "CREATE_NEW_VIP_PLAN";
export const EDIT_VIP_PLAN = "EDIT_VIP_PLAN";
export const DELETE_VIP_PLAN = "DELETE_VIP_PLAN";
export const RENEWAL_SWITCH = "RENEWAL_SWITCH";

export const OPEN_VIP_PLAN_DIALOG = "OPEN_VIP_PLAN_DIALOG";
export const CLOSE_VIP_PLAN_DIALOG = "CLOSE_VIP_PLAN_DIALOG";

export const GET_VIP_PLAN_HISTORY = "GET_VIP_PLAN_HISTORY";
