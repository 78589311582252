import axios from "axios";
import {Toast} from "../../util/Toast";

import {GET_PAYOUT, ACCEPT_PAYOUT, DECLINE_PAYOUT, CLOSE_DECLINE_DIALOG} from "./types";

export const getRedeem = (status) => (dispatch) => {
  axios
    .get(`payout?status=${status}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({type: GET_PAYOUT, payload: res.data.payouts});
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};

export const acceptRedeem = (id) => (dispatch) => {
  axios
    .post(`payout/accept?payoutId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({type: ACCEPT_PAYOUT, payload: id});
        return Toast("success", "Accept Success!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};


export const declineRedeem = (id, description) => (dispatch) => {
  axios
    .post(`payout/decline?payoutId=${id}&description=${description}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({type: DECLINE_PAYOUT, payload: id});
        dispatch({type: CLOSE_DECLINE_DIALOG });
        return Toast("success", "Decline Success!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};
