import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  CLOSE_BACKGROUND_DIALOG,
  CREATE_NEW_BACKGROUND,
  DELETE_BACKGROUND,
  EDIT_BACKGROUND,
  GET_BACKGROUND,
} from "./types";

export const getBackground = () => (dispatch) => {
  axios
    .get(`background`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_BACKGROUND, payload: res.data.background });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const createNewBackground = (data) => (dispatch) => {
  axios
    .post(`background`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Background created successfully!");
        dispatch({ type: CLOSE_BACKGROUND_DIALOG });
        dispatch({ type: CREATE_NEW_BACKGROUND, payload: res.data.background });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editBackground = (id, data) => (dispatch) => {
  axios
    .patch(`background/${id}`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Background updated successfully!");
        dispatch({
          type: EDIT_BACKGROUND,
          payload: { data: res.data.background, id },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const deleteBackground = (backgroundId) => (dispatch) => {
  axios
    .delete(`background/${backgroundId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_BACKGROUND, payload: backgroundId });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
