import {
    CLOSE_PAYMENT_METHOD_DIALOG,
    CREATE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD,
    EDIT_PAYMENT_METHOD,
    GET_PAYMENTMETHOD,
    OPEN_PAYMENT_METHOD_DIALOG,
  } from "./types";
  
  const initialState = {
    paymentMethod: [],
    dialog: false,
    dialogData: null,
  };
  
  const paymentMethodReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PAYMENTMETHOD:
        return {
          ...state,
          paymentMethod: action.payload,
        };
      case CREATE_PAYMENT_METHOD:
        const data = [...state.paymentMethod];
        data.unshift(action.payload);
        return {
          ...state,
          paymentMethod: data,
        };
      case EDIT_PAYMENT_METHOD:
        return {
          ...state,
          paymentMethod: state.paymentMethod.map((paymentMethod) => {
            if (paymentMethod._id === action.payload.id) return action.payload.data;
            else return paymentMethod;
          }),
        };
      case DELETE_PAYMENT_METHOD:
        return {
          ...state,
          paymentMethod: state.paymentMethod.filter((paymentMethod) => paymentMethod._id !== action.payload),
        };
      case OPEN_PAYMENT_METHOD_DIALOG:
        return {
          ...state,
          dialog: true,
          dialogData: action.payload || null,
        };
      case CLOSE_PAYMENT_METHOD_DIALOG:
        return {
          ...state,
          dialog: false,
          dialogData: null,
        };
  
      default:
        return state;
    }
  };
  
  export default paymentMethodReducer;
  