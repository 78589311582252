/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Switch,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_GIFT_DIALOG } from "../../../store/gift/types";

//action
import { editGift } from "../../../store/gift/action";
import { getCategory } from "../../../store/giftCategory/action";

import SVGA from "svgaplayerweb";

// server path
import { baseURL } from "../../../util/Config";

import { permissionError } from "../../../util/Alert";

const GiftDialog = (props) => {
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector((state) => state.gift);

  const GiftClick = localStorage.getItem("GiftClick");

  const [mongoId, setMongoId] = useState("");
  const [coin, setCoin] = useState("");
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState();

  const [previewImagePath, setPreviewImagePath] = useState("");
  const [previewImageData, setPreviewImageData] = useState("");
  const [imageData, setImageData] = useState("");
  const [imagePath, setImagePath] = useState("");

  const [isSvga, setIsSvga] = useState();
  const [showInStore, setShowInStore] = useState(true);

  const categoryDetail = JSON.parse(localStorage.getItem("Category"));

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  useEffect(() => {
    props.getCategory(); // eslint-disable-next-line
  }, []);

  const categories = useSelector((state) => state.giftCategory.giftCategory);

  const [errors, setError] = useState({
    image: "",
    name: "",
    coin: "",
    category: "",
  });

  useEffect(() => {
    if (dialogData) {
      console.log("dialog data:-=====", dialogData)
      setMongoId(dialogData._id);
      setCoin(dialogData.coin);
      setName(dialogData?.name || "");
      setType(dialogData.type);
      setIsSvga(dialogData.type === 2)

      setShowInStore(dialogData.showInStore);
      setCategory(
        GiftClick === null ? dialogData.category._id : dialogData.category
      );
      setImagePath(baseURL + dialogData.image);
      setPreviewImagePath(baseURL + dialogData.imagePreview)
    }
  }, [dialogData, GiftClick]);

  useEffect(
    () => () => {
      setError({
        image: "",
        coin: "",
        category: "",
      });
      setCategory("");
      setCoin("");
      setName("");
      setImageData(null);
      setImagePath(null);
      setPreviewImageData(null);
      setPreviewImagePath(null);
      setType(null);
    },
    [open]
  );

  const closePopup = () => {
    dispatch({ type: CLOSE_GIFT_DIALOG });
  };


  useEffect(() => {
    if (isSvga) {
      const svgaElement = document.getElementById("svgaAdd");
      if (svgaElement) {

        // Error handling
        try {
          var player = new SVGA.Player(`div[attr="addInputImage"]`);
          var parser = new SVGA.Parser(`div[attr="addInputImage}"]`);
          parser.load(imagePath, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          });
        } catch (error) {
          console.error("Error initializing SVGA Player:", error);
        }
      } else {
        console.error("SVGA element not found in the DOM");
      }
    }
  }, [isSvga]);

  useEffect(() => {
    if (imagePath && imageData && imageData.name) {
      var fileNameParts = imageData.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1];
      setIsSvga(fileExtension === "svga");
    }
  }, [imagePath]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !coin ||
      (GiftClick !== null && (category === "Select Category" || !category))
      || !name || !imagePath || !previewImagePath
    ) {
      const errors = {};

      if (!coin) errors.coin = "Diamond is Required!";

      if (coin < 0) errors.coin = "Invalid Diamond ";

      if (!name) errors.name = "Name is Required";

      if (!imagePath) errors.imagePath = "Image is Required";

      if (!previewImagePath) errors.previewImagePath = "Preview image is Required";

      // TODO: Image validation
      if (GiftClick !== null && (category === "Select Category" || !category)) {
        errors.category = "Please select a Category!";
      }
      return setError({ ...errors });
    }

    const coinValid = isNumeric(coin);
    if (!coinValid) {
      return setError({ ...errors, coin: "Invalid Diamond!!" });
    }
    const validateCoin =
      coin.toString().includes("-") || coin.toString().includes(".");

    if (validateCoin) {
      return setError({ ...errors, coin: "Invalid Diamond Validity!!" });
    }
    const formData = new FormData();

    formData.append("imageVideo", imageData);
    formData.append("previewImage", previewImageData)
    formData.append("type", isSvga ? 2 : 1);
    formData.append("name", name);
    formData.append("coin", coin);
    formData.append("showInStore", showInStore);
    GiftClick !== null && formData.append("category", category);

    if (!hasPermission) return permissionError();

    props.editGift(formData, mongoId);
  };

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  // for preview image
  const handleInputAddImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  // for preview image
  const handleInputAddImagePreview = (e) => {
    if (e.target.files[0]) {
      setPreviewImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPreviewImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Gift </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="form-group">
                  <label className="mb-2 text-gray">Name</label>
                  <input
                    className="form-control"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          name: "Name is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          name: "",
                        });
                      }
                    }}
                  />
                  {errors.name && (
                    <div className="ml-2 mt-1">
                      {errors.name && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.name}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="mb-2 text-gray mt-4">Diamond</label>
                  <input
                    type="number"
                    className="form-control"
                    required=""
                    min="0"
                    placeholder="20"
                    value={coin}
                    onChange={(e) => {
                      setCoin(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          coin: "Diamond is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          coin: "",
                        });
                      }
                    }}
                  />
                  {errors.coin && (
                    <div className="ml-2 mt-1">
                      {errors.coin && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.coin}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                
                <div className="form-group mt-4">
                  <label className="text-gray mb-2">Show in store</label>

                  <Switch
                    checked={showInStore}
                    onChange={(event) => setShowInStore(event.target.checked)}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />

                </div>

                <div className="form-group mt-4">
                  <label className="text-gray mb-2">Category</label>
                  {GiftClick === null ? (
                    <input
                      type="text"
                      className="form-control"
                      required=""
                      placeholder="Category Name"
                      disabled
                      value={categoryDetail.name}
                    />
                  ) : (
                    <>
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                        value={category}
                        onChange={(e) => {
                          setCategory(e.target.value);
                          if (e.target.value === "Select Category") {
                            return setError({
                              ...errors,
                              category: "Please select a Category!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              category: "",
                            });
                          }
                        }}
                      >
                        <option value="Select Category" selected>
                          Select Category
                        </option>
                        {categories.map((category) => {
                          return (
                            <option value={category._id}>
                              {category.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.category && (
                        <div className="ml-2 mt-1">
                          {errors.category && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.category}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>

                <>
                  <div className="form-group mt-4" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="mb-2 text-gray">Select Image</label>
                    <input
                      type="file"
                      accept=".svga, .gif"
                      className="form-control form-control-sm"
                      required=""
                      onChange={handleInputAddImage}
                    />
                    {errors.imagePath && (
                      <div className="ml-2 mt-1">
                        {errors.imagePath && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.imagePath}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {imagePath && isSvga && (
                      <>
                        <div
                          id="svgaAdd"
                          attr="addInputImage"
                          style={{
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                            marginTop: 10,
                            float: "left",
                            objectFit: "contain",
                            marginRight: 15,
                            height: "100px",
                            width: "100px",
                          }}
                        ></div>
                      </>
                    )}
                    {imagePath && !isSvga && (
                      <>
                        <img
                          src={imagePath}
                          className="mt-3 rounded float-left mb-2"
                          height="100px"
                          width="100px"
                        />
                      </>
                    )}
                  </div>

                  {/* preview image */}
                  <div className="form-group mt-4">
                    <label className="mb-2 text-gray">Select Preview Image</label>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control form-control-sm"
                      required=""
                      onChange={handleInputAddImagePreview}
                    />

                    {errors.previewImagePath && (
                      <div className="ml-2 mt-1">
                        {errors.previewImagePath && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.previewImagePath}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {previewImagePath && (
                      <>
                        <img
                          src={previewImagePath}
                          className="mt-3 rounded float-left mb-2"
                          height="100px"
                          width="100px"
                        />
                      </>
                    )}
                  </div>
                </>

                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { editGift, getCategory })(GiftDialog);
