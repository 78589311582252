import { GET_DEACTIVATION_REQUEST } from "./types";

const initialState = {
  deactivationRequest: [],
};

const deactivationRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEACTIVATION_REQUEST:
      return {
        ...state,
        deactivationRequest: action.payload,
      };

    default:
      return state;
  }
};

export default deactivationRequestReducer;
