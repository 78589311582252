import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  CLOSE_PAYMENT_METHOD_DIALOG,
  CREATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  EDIT_PAYMENT_METHOD,
  GET_PAYMENTMETHOD,
} from "./types";

export const getPaymentMethod = () => (dispatch) => {
  axios
    .get(`paymentMethod`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_PAYMENTMETHOD, payload: res.data.paymentMethods });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const createPaymentMethod = (data) => (dispatch) => {
  console.log(data);
  axios
    .post(`paymentMethod`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Payment Method created successfully!");
        dispatch({ type: CLOSE_PAYMENT_METHOD_DIALOG });
        dispatch({ type: CREATE_PAYMENT_METHOD, payload: res.data.paymentMethod });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editPaymentMethod = (mongoId, data) => (dispatch) => {
  console.log(data)
  axios
    .patch('paymentMethod', data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Payment Method updated successfully!");
        dispatch({ type: CLOSE_PAYMENT_METHOD_DIALOG });
        dispatch({
          type: EDIT_PAYMENT_METHOD,
          payload: { data: res.data.paymentMethod, id: mongoId },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const deletePaymentMethod = (id) => (dispatch) => {
  axios
    .delete(`paymentMethod/?id=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_PAYMENT_METHOD, payload: id });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
