import {
  CLOSE_DIAMOND_SHOP_DIALOG,
  CREATE_SHOP_DIAMOND,
  DELETE_SHOP_DIAMOND,
  EDIT_SHOP_DIAMOND,
  GET_SELLER_SHOP_DIAMOND,
  GET_SHOP_DIAMOND,
  OPEN_DIAMOND_SHOP_DIALOG,
} from "./types";

const initialState = {
  shopDiamond: [],
  dialog: false,
  dialogData: null,
};

const diamondShopReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOP_DIAMOND:
      return {
        ...state,
        shopDiamond: action.payload,
      };
    case GET_SELLER_SHOP_DIAMOND:
      return {
        ...state,
        shopDiamond: action.payload,
      };
    case CREATE_SHOP_DIAMOND:
      const data = [...state.shopDiamond];
      data.unshift(action.payload);
      return {
        ...state,
        shopDiamond: data,
      };
    case EDIT_SHOP_DIAMOND:
      return {
        ...state,
        shopDiamond: state.shopDiamond.map((shopDiamond) => {
          if (shopDiamond._id === action.payload.id) return action.payload.data;
          else return shopDiamond;
        }),
      };
    case DELETE_SHOP_DIAMOND:
      return {
        ...state,
        shopDiamond: state.shopDiamond.filter((shopDiamond) => shopDiamond._id !== action.payload),
      };
    case OPEN_DIAMOND_SHOP_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_DIAMOND_SHOP_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};

export default diamondShopReducer;
