import { GET_PAYOUT, ACCEPT_PAYOUT, DECLINE_PAYOUT, OPEN_DECLINE_DIALOG, CLOSE_DECLINE_DIALOG } from "./types";

const initialState = {
  redeem: [],
  dialog: false,
  dialogData: null,
};

const redeemReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYOUT:
      return {
        ...state,
        redeem: action.payload,
      };

    case ACCEPT_PAYOUT:
      return {
        ...state,
        redeem: state?.redeem?.filter(
          (redeem) => redeem?._id !== action?.payload && redeem
        ),
      };

    case DECLINE_PAYOUT:
      return {
        ...state,
        redeem: state?.redeem?.filter(
          (redeem) => redeem?._id !== action?.payload && redeem
        ),
      };
    case OPEN_DECLINE_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_DECLINE_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};

export default redeemReducer;
