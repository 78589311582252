import {
  CLOSE_BACKGROUND_DIALOG,
  CREATE_NEW_BACKGROUND,
  DELETE_BACKGROUND,
  EDIT_BACKGROUND,
  GET_BACKGROUND,
  OPEN_BACKGROUND_DIALOG,
} from "./types";

const initialState = {
  background: [],
  Dialogue: false,
  DialogueData: null,
};

const backgroundReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BACKGROUND:
      return {
        ...state,
        background: action.payload,
      };
    case CREATE_NEW_BACKGROUND:
      const data = [...state.background];
      data.unshift(action.payload);
      return {
        ...state,
        background: data,
      };
    case EDIT_BACKGROUND:
      return {
        ...state,
        background: state.background.map((background) => {
          if (background._id === action.payload.id) return action.payload.data;
          else return background;
        }),
      };
    case DELETE_BACKGROUND:
      return {
        ...state,
        background: state.background.filter((background) => background._id !== action.payload),
      };
    case OPEN_BACKGROUND_DIALOG:
      return {
        ...state,
        Dialogue: true,
        DialogueData: action.payload || null,
      };
    case CLOSE_BACKGROUND_DIALOG:
      return {
        ...state,
        Dialogue: false,
        DialogueData: null,
      };

    default:
      return state;
  }
};

export default backgroundReducer;
