import axios from "axios";
import { GET_GAME_HISTORY, RESET_COIN } from "./type";
import {
  CLOSE_SPINNER_PROGRESS,
  OPEN_SPINNER_PROGRESS,
} from "../spinner/types";
import { Toast } from "../../util/Toast";

export const getGameHistory = (start, limit, startDate, endDate) => (dispatch) => {
  dispatch({ type: OPEN_SPINNER_PROGRESS });
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  const url = `https://game.funmateapp.com/gameHistory/get?start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;
  
  fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      return response.json();
    })
    .then((res) => {
      console.log("Response:", res);
      dispatch({ type: GET_GAME_HISTORY, payload: res });
    })
    .catch((error) => {
      console.error("Fetch error:", error);
    })
    .finally(() => {
      dispatch({ type: CLOSE_SPINNER_PROGRESS });
    });
};



export const resetGameCoin = () => (dispatch) => {
  axios
    .get("/gameAdminCoin/reset")
    .then((res) => {
     
      if (res.data.status) {
        dispatch({ type: RESET_COIN, payload: res.data.gameAdminCoin.coin });
        Toast("success", "Admin Diamond Reset Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log("error", error));
};
