import React, { useEffect, useState } from "react";

//react-redux
import { useSelector, connect } from "react-redux";

//routing
import { Link } from "react-router-dom";

import arraySort from "array-sort";

//action
import { getRedeem } from "../../store/payout/action";
import { getSetting } from "../../store/setting/action";

//MUI
import { TablePagination } from "@material-ui/core";

//dayjs
import dayjs from "dayjs";

const TablePaginationActions = React.lazy(() => import("./TablePagination"));

const AcceptedRedeemTable = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [coinSort, setCoinSort] = useState(true);

  useEffect(() => {
    props.getSetting();
    props.getRedeem("ACCEPTED"); // eslint-disable-next-line
  }, []);

  const redeem = useSelector((state) => state.payout.redeem);
  const setting = useSelector((state) => state.setting.setting);

  useEffect(() => {
    setData(redeem);
  }, [redeem]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase()
      ? e.target.value.trim().toUpperCase()
      : e.target.value.trim();
    if (value) {
      const data = redeem.filter((data) => {
        return (
          data?.userId?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.paymentGateway?.toUpperCase()?.indexOf(value) > -1 ||
          data?.description?.toUpperCase()?.indexOf(value) > -1 ||
          data?.rCoin?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(redeem);
    }
  };

  const handleCoinSort = () => {
    setCoinSort(!coinSort);
    arraySort(data, "vcoin", { reverse: coinSort });
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Accepted Redeem Request</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Redeem
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Accepted Redeem
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left"></div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={handleSearch}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body card-overflow">
              <div className="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>User</th>
                    <th>Payment Gateway</th>
                    <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      VCoin {coinSort ? " ▼" : " ▲"}
                    </th>
                    <th>
                      {setting.currency === "£"
                        ? "GBP(£)"
                        : setting.currency === "$"
                          ? "Dollar($)"
                          : "PHP(₱)"}
                    </th>
                    <th>Accepted date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    (rowsPerPage > 0
                      ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : data
                    ).map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.user?.name}</td>
                          <td>{data.userPaymentMethod.paymentMethod.name}</td>
                          <td>{data.vcoin}</td>
                          <td>
                            {(data.vcoin / setting.rCoinForCashOut).toFixed(2)}
                          </td>
                          <td>
                            {dayjs(data.updatedAt).format("DD MMM, YYYY")}
                          </td>
                          <td>
                            <span style={{ color: data.status === "SUCCEEDED" ? "#4CAF50" : data.status === "FAILED" ? "#F44336" : "#2196F3" }}>
                              {data.status}
                            </span>
                          </td>

                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <TablePagination
                id="pagination"
                component="div"
                rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getRedeem, getSetting })(AcceptedRedeemTable);
